import {ApiBase} from '@jetCommon/api/base.js';

export default class CompanyAbsenceRequestKindApiCommon extends ApiBase {
    static resourceName = 'company_absence_request_kinds';

    choices(params) {
        return this.apiGet('choices/', {params});
    }

    attachmentChoices(params) {
        return this.apiGet('attachment_choices/', {params});
    }
}
