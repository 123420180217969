import {ApiBase} from '@jetCommon/api/base.js';

export default class CompanySettingsApi extends ApiBase {
    static resourceName = 'company_settings';

    getExtraMonthlyPayChoices() {
        return this.apiGet('extra_monthly_pay_choices/');
    }

    getAbsencesExpensesChoices() {
        return this.apiGet('absences_expenses_choices/');
    }

    getPayDayChoices() {
        return this.apiGet('pay_day_choices/');
    }

    getPayMonthChoices() {
        return this.apiGet('pay_month_choices/');
    }

    getSlaTimeChoices() {
        return this.apiGet('sla_time_choices/');
    }

    getPayrollElaborationModeChoices() {
        return this.apiGet('payroll_elaboration_mode_choices/');
    }
}
