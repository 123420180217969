import PayrollApiCommon from '@jetCommon/api/payroll.js';

export default class PayrollApiService extends PayrollApiCommon {
    getChoices(params) {
        return this.apiGet('choices/', {params});
    }

    getCurrentRelevantPayrolls() {
        return this.apiGet('current_relevant_payrolls/');
    }

    setAsDraft(id, data) {
        return this.apiPost(`${id}/set_as_draft/`, data);
    }

    openChanges(id, data) {
        return this.apiPost(`${id}/open_changes/`, data);
    }

    closeChanges(id, data) {
        return this.apiPost(`${id}/close_changes/`, data);
    }

    setF24Amount(id, data) {
        return this.apiPost(`${id}/set_f24_amount/`, data);
    }

    loadBusinessCostFile(id, data) {
        return this.apiPost(`${id}/load_business_cost_file/`, data);
    }

    loadNetSalariesFile(id, data) {
        return this.apiPost(`${id}/load_net_salaries_file/`, data);
    }

    loadF24File(id, data) {
        return this.apiPost(`${id}/load_f24_file/`, data);
    }

    completeProcess(id, data) {
        return this.apiPost(`${id}/complete_process/`, data);
    }

    getAnomalies(id) {
        return this.apiGet(`${id}/anomalies/`);
    }

    notifyPayslipsUpdated(id) {
        return this.apiPost(`${id}/notify_payslips_updated/`);
    }

    closePayroll(id) {
        return this.apiPost(`${id}/close_payroll/`);
    }

    closePayrolls(ids) {
        return this.apiPost('close_payrolls/', {payroll_ids: ids});
    }

    importDataFromPe(id) {
        return this.apiPost(`${id}/import_data_from_pe/`);
    }

    getReport(id, reportType) {
        return this.getApiUrl(`${id}/download_report/?report-type=${reportType}`, true);
    }

    payslipsUpload(id, data) {
        return this.apiPostForm(`${id}/bulk_upload_payslips/`, data);
    }

    documentsUpload(id, data) {
        return this.apiPostForm(`${id}/bulk_upload_documents/`, data);
    }

    lulDocumentsUpload(data) {
        return this.apiPostForm('bulk_upload_lul_documents/', data);
    }

    getEmployeesCounts(id) {
        return this.apiGet(`${id}/get_employees_counts/`);
    }

    requestXlsDownload(params) {
        return this.apiGet('download_xlsx/', params);
    }

    getReopeningReasonChoices() {
        return this.apiGet('reopening_reason_choices/');
    }
}
