import {end} from '@jetCommon/api.js';
import AccountantApiCommon from '@jetCommon/api/accountant.js';
import AdminApi from '@/api/admin.js';
import AtecoApiCommon from '@jetCommon/data-stores-api/ateco.js';
import AuthApi from '@/api/auth.js';
import CCNLApiCommon from '@jetCommon/data-stores-api/ccnl.js';
import CompanyAbsenceRequestKindApiCommon from '@jetCommon/api/company-absence-request-kind.js';
import CompanyAddressApi from '@/api/company-address.js';
import CompanyApi from '@/api/company.js';
import CompanyBoardDocumentApiCommon from '@jetCommon/api/company-board-document.js';
import CompanyCCNLSettingsApiCommon from '@jetCommon/api/company-ccnl-settings.js';
import CompanyContactsApi from '@/api/company-contacts.js';
import CompanyDocumentApi from '@/api/company-document.js';
import CompanyGroupApi from '@/api/company-group.js';
import CompanyHeadquartersPolicyApi from '@jetCommon/api/company-headquarters-policy.js';
import CompanyNoteApi from '@/api/company-note.js';
import CompanyOnboardingApi from '@/api/company-onboarding.js';
import CompanyOnboardingTaskApi from '@/api/company-onboarding-task.js';
import CompanyOnboardingTaskDocumentApi from '@/api/company-onboarding-task-document.js';
import CompanyPaymentApi from '@/api/company-payment.js';
import CompanyPayslipExtraBonusKindApiCommon from '@jetCommon/api/company-payslip-extra-bonus-kind.js';
import CompanySalaryPaymentSettingsApi from '@/api/company-salary-payment-settings.js';
import CompanySettingsApi from '@/api/company-settings.js';
import CompanyWorkingHoursApiCommon from '@jetCommon/api/company-working-hours.js';
import EmployeeApi from '@/api/employee.js';
import EmployeeDocumentApi from '@/api/employee-document.js';
import EmployeeHiringApi from '@/api/employee-hiring.js';
import EmployeeWorkingHoursApiCommon from '@jetCommon/api/employee-working-hours.js';
import ExpenseApiCommon from '@jetCommon/api/expense.js';
import ExtraServiceApi from '@/api/extra-service.js';
import HiringApi from '@/api/hiring.js';
import HiringDocumentCommonApi from '@jetCommon/api/hiring-document.js';
import HolidaysApiCommon from '@jetCommon/api/holidays';
import JetAsyncTaskApi from '@/api/async-task.js';
import LabourConsultantApi from '@/api/labour-consultant.js';
import LocationApiCommon from '@jetCommon/api/location.js';
import OperatorApi from '@/api/operator.js';
import PayrollApi from '@/api/payroll.js';
import PayrollAttachmentsApiCommon from '@jetCommon/api/payroll-attachments';
import PayslipApi from '@/api/payslip.js';
import PersonApiCommon from '@jetCommon/api/person.js';
import SpidPasswordlessAuthenticationCommon from '@jetCommon/api/spid-passwordless-authentication.js';
import ToolsApi from '@/api/tools.js';
import UploadedFilesApiCommon from '@jetCommon/api/uploadedFiles.js';

export const api = {
    end,
    accountants: new AccountantApiCommon(),
    admins: new AdminApi(),
    asyncTasks: new JetAsyncTaskApi(),
    auth: new AuthApi(),
    ateco: new AtecoApiCommon(),
    ccnl: new CCNLApiCommon(),
    companies: new CompanyApi(),
    companyAbsenceRequestKinds: new CompanyAbsenceRequestKindApiCommon(),
    companyAddresses: new CompanyAddressApi(),
    companyContacts: new CompanyContactsApi(),
    companyDocuments: new CompanyDocumentApi(),
    companyBoardDocuments: new CompanyBoardDocumentApiCommon(),
    companyCCNLSettings: new CompanyCCNLSettingsApiCommon(),
    companyGroups: new CompanyGroupApi(),
    companyHeadquartersPolicy: new CompanyHeadquartersPolicyApi(),
    companyNotes: new CompanyNoteApi(),
    companyOnboardingTaskDocuments: new CompanyOnboardingTaskDocumentApi(),
    companyOnboarding: new CompanyOnboardingApi(),
    companyOnboardingTasks: new CompanyOnboardingTaskApi(),
    companyPayments: new CompanyPaymentApi(),
    companyPayslipExtraBonusKinds: new CompanyPayslipExtraBonusKindApiCommon(),
    companySalaryPaymentSettings: new CompanySalaryPaymentSettingsApi(),
    companySettings: new CompanySettingsApi(),
    companyWorkingHours: new CompanyWorkingHoursApiCommon(),
    employeeDocuments: new EmployeeDocumentApi(),
    employeeHirings: new EmployeeHiringApi(),
    employeeWorkingHours: new EmployeeWorkingHoursApiCommon(),
    employees: new EmployeeApi(),
    expenses: new ExpenseApiCommon(),
    extraServices: new ExtraServiceApi(),
    hirings: new HiringApi(),
    hiringDocuments: new HiringDocumentCommonApi(),
    holidays: new HolidaysApiCommon(),
    labourConsultants: new LabourConsultantApi(),
    locations: new LocationApiCommon(),
    operators: new OperatorApi(),
    payrollAttachments: new PayrollAttachmentsApiCommon(),
    payrolls: new PayrollApi(),
    payslips: new PayslipApi(),
    persons: new PersonApiCommon(),
    spidPasswordlessAuthentication: new SpidPasswordlessAuthenticationCommon(),
    uploadedFiles: new UploadedFilesApiCommon(),
    tools: new ToolsApi(),
};
